import * as Yup from 'yup';

const GMCSchemaStepTwo = Yup.object().shape({
  company: Yup.string()
    .min(3, 'Please enter the full company name')
    .max(200, 'Please enter a name less than 200')
    .required('Please enter the full company name'),
  
  
  // firstTime: Yup.string()
  //   .required('Please select one'),
  // sumInsuredType: Yup.string().when('firstTime', {
  //   is: true,
  //   then: schema => schema.required('Please select one'),
  // }),
  //  bizstructure: Yup.string().required('Choose a structure'),
});

export default GMCSchemaStepTwo;